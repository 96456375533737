import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit'

const FAQ = () => {
  return (
    <section id="faq">
      <Container>
        <Title
          color='dark'
          sub='grey'
          title='Frequently Asked Questions'
          subtitle='Have questions? Find answers to common queries about CraftFlow.'
        />
        <div className="w-80 m-100 ma">
          <MDBContainer className="mt-5">
            <MDBAccordion alwaysOpen initialActive={1}>
              <MDBAccordionItem
                className='c-grey'
                collapseId={1}
                headerTitle="What design solutions are included in your fixed-fee service for businesses?"
              >
                Lorem ipsum dolor sit amet consectetur. Pharetra consectetur blandit sit feugiat morbi. Porttitor semper nunc turpis elit faucibus nibh. Sem ipsum justo viverra id massa. Ac odio sit diam feugiat ac tempus sit amet a. Sapien venenatis malesuada at in vitae ultrices a. Nunc vitae tincidunt aliquam consectetur venenatis amet. Urna sed nunc a tellus nibh. Phasellus eget ullamcorper in eleifend aliquet. Nisi venenatis sit condimentum.
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-grey'
                collapseId={2}
                headerTitle="How long does it typically take to create design solutions after payment?"
              >
                Lorem ipsum dolor sit amet consectetur. Pharetra consectetur blandit sit feugiat morbi. Porttitor semper nunc turpis elit faucibus nibh. Sem ipsum justo viverra id massa. Ac odio sit diam feugiat ac tempus sit amet a. Sapien venenatis malesuada at in vitae ultrices a. Nunc vitae tincidunt aliquam consectetur venenatis amet. Urna sed nunc a tellus nibh. Phasellus eget ullamcorper in eleifend aliquet. Nisi venenatis sit condimentum.
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-grey'
                collapseId={3}
                headerTitle="Does the fixed fee include consultation support?"
              >
                Lorem ipsum dolor sit amet consectetur. Pharetra consectetur blandit sit feugiat morbi. Porttitor semper nunc turpis elit faucibus nibh. Sem ipsum justo viverra id massa. Ac odio sit diam feugiat ac tempus sit amet a. Sapien venenatis malesuada at in vitae ultrices a. Nunc vitae tincidunt aliquam consectetur venenatis amet. Urna sed nunc a tellus nibh. Phasellus eget ullamcorper in eleifend aliquet. Nisi venenatis sit condimentum.
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-grey'
                collapseId={4}
                headerTitle="Is there a time limit for the duration of the fixed fee?"
              >
                Lorem ipsum dolor sit amet consectetur. Pharetra consectetur blandit sit feugiat morbi. Porttitor semper nunc turpis elit faucibus nibh. Sem ipsum justo viverra id massa. Ac odio sit diam feugiat ac tempus sit amet a. Sapien venenatis malesuada at in vitae ultrices a. Nunc vitae tincidunt aliquam consectetur venenatis amet. Urna sed nunc a tellus nibh. Phasellus eget ullamcorper in eleifend aliquet. Nisi venenatis sit condimentum.
              </MDBAccordionItem>
            </MDBAccordion>
          </MDBContainer>
        </div>
        <div className="s-80"></div>
      </Container>
    </section>
  )
}
export { FAQ }
