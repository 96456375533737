import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import photo from '../../assets/images/Andrey.png'
import pic from '../../assets/images/pic-ab-2.webp'

const About = () => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title='About me'
        subtitle='I am a passionate expert in the IT industry with over 25 years of experience, and I have extensive experience working with UI/UX designers.'
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <img className='rounded' src={pic} alt='The new home for your design aspirations' width={'100%'} height={480} />
        </div>
        <div className="col-lg-6 flex jcc aic">
          <div>
            <img src={photo} className='rounded' alt={'ANDRII ROGOVSKY'} width={104} height={104} />
            <div className="h-30"></div>
            <p className='title-section-sub w-60 mb-4 ma c-dark mb-2'>I invite everyone to join an open online championship aimed at developing innovative products for the creative industry.<br />All financial proceeds will be donated to a charitable fund supporting disabled veterans.</p>
            <h5 className='title mt-3 w-40 ma c-grey'>ANDRII ROGOVSKY</h5>
          </div>
        </div>
      </div>
      <div className="s-120"></div>
    </Container>
  )
}

export { About }
