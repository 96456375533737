import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon from '../../assets/images/icons/pool.svg'
import pic from '../../assets/images/features.webp'

const Pool = () => {
  return (
    <section className='bg-light'>
      <Container className='tc'>
        <Title
          width={80}
          color='dark'
          sub='grey'
          title='CraftFlow Pool'
          subtitle='We will select 200 candidates from the applicant pool to complete a selection task.'
        />
        <div className="row g-0">
          <div className="col-lg-6">
            <Card
              back='pink'
              sub='This task will focus on evaluating your product vision. There is no need to sign any NDA – simply unleash your creativity and publish your work on your profile!'
              icon={icon}
              width={120}
              height={120}
              title='CraftFlow'
              desc='creativity and publish'
              textColor='white'
              titleColor='yellow'
            />
          </div>
          <div className="col-lg-6">
            <img src={pic} alt="More than a platform it's a creative partner" width={'100%'} height={480} />
          </div>
        </div>
        <div className="s-120"></div>
      </Container>
    </section>
  )
}

export { Pool }
