import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon from '../../assets/images/icons/offer.png'

const Winner = () => {
  return (
    <Container className='tc'>
      <Title
        width={70}
        color='dark'
        sub='grey'
        title='Winner'
        subtitle="The winner will receive a Co-Founder's offer in a product for the creative industry,
along with 10% of the proceeds"
      />
      <Card
        back='offer'
        sub=' Additionally, we will donate 80% of the proceeds to a fund dedicated to helping disabled veterans.'
        icon={icon}
        width={64}
        height={64}
        title='CraftFlow'
        desc="The other five participants will each receive 2% of the proceeds and premium accounts in the product, free of charge, for two years."
        text='Join to Contest'
        textColor='white'
        titleColor='yellow'
        buttonColor='orange'
      />
      <div className="s-120"></div>
    </Container>
  )
}

export { Winner }
