import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Action } from '../elements/Action'

import ReactPlayer from 'react-player/youtube'

const video = 'https://www.youtube.com/watch?v=8YdFcp7nNFk'

const Tour = () => {
  return (
    <section className="tour">
      <Container className='tc'>
        <Title
          width={40}
          color='white'
          sub='white'
          title='Virtual Tour'
          subtitle='Get a sneak peek of CraftFlow in action – Experience the future of design!'
        />
        <ReactPlayer
          className='react-player'
          controls={true}
          url={video}
          width='100%'
          height='97vh'
        />
        <Action
          color='white'
          back='pink'
          heading='Help us tailor CraftFlow to your creative journey'
          text='Share your thoughts'
        />
        <div className="s-120"></div>
      </Container>
    </section>
  )
}

export { Tour }
