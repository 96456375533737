import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon1 from '../../assets/images/icons/ab-1.png'
import icon2 from '../../assets/images/icons/ab-2.png'
import pic1 from '../../assets/images/pic-ab-1.webp'
import pic2 from '../../assets/images/pic-ab-2.webp'

const About = () => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title='About the Platform'
        subtitle='CraftFlow – Where Creativity Meets Innovation. Our mission is to empower designers with unmatched tools &amp; community support'
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <Card
            back='white'
            sub='From real-time collaboration to state-of-the-art design tools'
            icon={icon1}
            width={61}
            height={64}
            title='CraftFlow'
            desc="More than a platform it's a creative partner"
            text='become our partner'
            textColor='dark'
            titleColor='pink'
            buttonColor='theme'
          />
        </div>
        <div className="col-lg-6">
          <img src={pic1} alt="More than a platform it's a creative partner" width={'100%'} height={480} />
        </div>
        <div className="col-lg-6">
          <img src={pic2} alt='The new home for your design aspirations' width={'100%'} height={480} />
        </div>
        <div className="col-lg-6">
          <Card
            back='white'
            sub='Endorsed by top designers and creative minds'
            icon={icon2}
            width={67}
            height={64}
            title='CraftFlow'
            desc='The new home for your design aspirations'
            text='Join to craftflow'
            textColor='dark'
            titleColor='theme'
            buttonColor='pink'
          />
        </div>
      </div>
      <div className="s-120"></div>
    </Container>
  )
}

export { About }
