import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../../logo.png';
import en from '../../assets/images/lang/en.png';

const Header = () => {
  return (
    <>
      <div className='w-100 flex aic jcc'>
        <Container>
          <div className='flex aic jcsb'>
            <div className='top-menu left'>
              <a href='/' className='button outline small c-white'>
                Join Now
              </a>
            </div>
            <h1 className='site-title'>
              <a href='/' aria-label='CraftFlow Logo'>
                <img src={logo} alt='CraftFlow' />
              </a>
            </h1>
            <div className='top-menu'>
              <a href='#lang'>
                <img
                  src={en}
                  alt='English'
                  width={16}
                  height={20}
                  className='pb-1'
                />
                <span>EN</span>
              </a>
              <a href='/login'>Login</a>
            </div>
          </div>
        </Container>
      </div>
      <div className='top-nav flex jcc aic'>
        <a href='/'>Home</a>
        <a href='/for-designers'>Designers</a>
        <a href='/for-agencies'>Agencies</a>
        <a href='/for-business'>Business</a>
        <a href='/contest'>Contest</a>
      </div>
    </>
  );
};

export { Header };
