import React from 'react';
import { Button } from '../elements/Button';

const Intro = () => {
  return (
    <div id='hero' className='main-hero'>
      <div className='container tc'>
        <h2 className='title-intro c-white w-80 ma'>
          Marathon-Hackathon For UI/UX Designers
        </h2>
        <div className='s-12'></div>
        <h3 className='title-intro-sub c-white w-80 ma'>
          We invite Funders, CEOs, Directors, and other executives from
          companies in the Creative Industry to serve as members of our Jury
          Team.
        </h3>
        <div className='s-40'></div>
        <Button color='pink' text='Become Jury' />
      </div>
    </div>
  );
};

export { Intro };
