import React from 'react'
import { Container } from 'react-bootstrap'

const CtaOne = () => {
  return (
    <section className="h-100-v flex jcc aic cta-1">
      <Container className='tc'>
        <h3 className='title-cta c-white w-80 ma'>Please register on the website to subscribe to notifications.</h3>
        <div className="s-20"></div>
        <p className='title-item c-white w-60 ma'>Registration is crucial to receive updates about the cohort, the qualifying rounds, the event's commencement, and much more</p>
        <div className="s-40"></div>
        <a href='https://craftflow.art' className='button bg-pink c-white'>Register today!</a>
      </Container>
    </section>
  )
}

export { CtaOne }
