import React from 'react';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { About } from '../components/parts/About';
import { CtaOne } from '../components/parts/CtaOne';
import { Features } from '../components/parts/Features';
import { Tour } from '../components/parts/Tour';
import { Join } from '../components/parts/Join';
import { CtaTwo } from '../components/parts/CtaTwo';
import { FAQ } from '../components/parts/FAQ';
import { Offer } from '../components/parts/Offer';

const Home = () => {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <CtaOne />
      <Features />
      <Tour />
      <Join />
      <CtaTwo />
      <FAQ />
      <Offer />
    </>
  );
};

export { Home };
