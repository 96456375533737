import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Action } from '../elements/Action'

import { Slideshow } from '../elements/Slideshow'
import agencies from '../data/agencies'

const cai = false

const Contest = () => {

  return (
    <Container className='tc'>
      <Title
        color='dark'
        sub='grey'
        title='Contest'
        subtitle='We will choose six candidates who are ready to work on six tasks, each lasting six weeks'
      />
      <div className='row'>
        <div className='col-lg-12'>
          <div className="s-40"></div>
          <Slideshow ai={cai}>
            {agencies.map(e => (
              <a className='item' href={e.link} aria-label={e.title} target='blank' key={e.id}>
                <div className='item-content tc flex aic jcc'>
                  <div className="m-5">
                    <img src={e.avatar} alt={e.name} width={48} height={48} className='r-24 ma mb-3' />
                    <h4 className='title-name c-dark'>{e.name}</h4>
                    <div className="s-20"></div>
                    <h3 className='title-item c-dark'>{e.title}</h3>
                    <div className="s-20"></div>
                    <a href={e.link} className='button small bg-pink c-white'>View</a>
                  </div>
                </div>
                <img width={'100%'} height={400} src={e.pic} alt={e.title} className='work' />
              </a>
            ))}
          </Slideshow>
        </div>
      </div>
      <Action
        color='dark'
        back='pink'
        heading='Join the ranks of thousands of designers who are eagerly waiting for CraftFlow'
        text='Join Now'
      />
      <div className='s-120'></div>
    </Container>
  )
}

export { Contest }
