import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  const year = new Date();

  return (
    <footer className='bg-light'>
      <Container>
        <div className='s-60'></div>
        <div className='flex jcsb aic px-2'>
          <p className='c-grey my-0 py-0'>
            Public Organization "DVOSTOK"
            <br />
            Registration Number: 44740443
          </p>
          <div className='menu-footer'>
            <a href='/privacy-policy' className='c-grey'>
              Privacy Policy
            </a>
            <span className='px-3 c-grey'>|</span>
            <a href='/terms-of-use' className='c-grey'>
              Terms of Use
            </a>
            <span className='px-3 c-grey'>|</span>
            <a href='/cookie-policy' className='c-grey'>
              Cookie Policy
            </a>
          </div>
        </div>
        <div className='s-60'></div>
      </Container>
      <p className='c-grey my-0 py-4 tc border-top'>
        Copyright © {year.getFullYear()} CraftFlow. All rights reserved.
      </p>
    </footer>
  );
};

export { Footer };
