import React from 'react';
import { Button } from '../elements/Button';

const Intro = () => {
  return (
    <div id='hero' className='main-hero'>
      <div className='h-156'></div>
      <div className='container tc'>
        <h2 className='title-intro c-white w-80 ma'>
          Join CraftFlow &amp; Revolutionize Your Design Journey!
        </h2>
        <div className='s-12'></div>
        <h3 className='title-intro-sub c-white'>
          Discover exclusive features tailored for creative minds
        </h3>
        <div className='s-40'></div>
        <Button color='pink' text='Sign Up for Early Access' />
      </div>
      <div className='h-156'></div>
    </div>
  );
};

export { Intro };
