import React from 'react'
import { Container } from 'react-bootstrap'

const CtaTwo = () => {
  return (
    <section className="h-100-v flex jcc aic cta-2">
      <Container className='tc'>
        <h3 className='title-cta c-white'>Learn More About CraftFlow Revolutionary Features</h3>
        <div className="s-40"></div>
        <a href='/' className='button bg-orange c-white'>Learn more</a>
      </Container>
    </section>
  )
}

export { CtaTwo }
