import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { ContestPage } from './pages/ContestPage';
import { ForAgencies } from './pages/ForAgencies';
import { ForDesigners } from './pages/ForDesigners';
import { ForBusiness } from './pages/ForBusiness';
import { Projects } from './pages/Projects';
import { Designers } from './pages/Designers';
import { Agencies } from './pages/Agencies';
import { FindWork } from './pages/FindWork';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { Refund } from './pages/Refund';
import { Cookie } from './pages/Cookie';
import { Error } from './pages/Error';
import { Footer } from './components/parts/Footer';

import './styles/custom.sass';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='for-designers' element={<ForDesigners />} />
        <Route path='for-agencies' element={<ForAgencies />} />
        <Route path='for-business' element={<ForBusiness />} />
        <Route path='contest' element={<ContestPage />} />
        <Route path='projects' element={<Projects />} />
        <Route path='designers' element={<Designers />} />
        <Route path='agencies' element={<Agencies />} />
        <Route path='find-work' element={<FindWork />} />
        <Route path='refund-policy' element={<Refund />} />
        <Route path='cookie-policy' element={<Cookie />} />
        <Route path='privacy-policy' element={<Privacy />} />
        <Route path='terms-of-use' element={<Terms />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export { App };
