import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon from '../../assets/images/icons/Selection.svg'
import pic from '../../assets/images/jury.jpeg'

const Jury = () => {
  return (
    <section className='bg-light'>
      <Container className='tc'>
        <Title
          width={80}
          color='dark'
          sub='grey'
          title='Knockout'
          subtitle="Each week, we will showcase participants' work for jury evaluation. The one with the lowest scores gets knocked out of the competition"
        />
        <div className="row g-0">
          <div className="col-lg-6">
            <Card
              back='jury'
              sub='We invite Funders, CEOs, Directors, and other executives from companies in the Creative Industry to serve as members of our Jury Team.'
              icon={icon}
              width={0}
              height={0}
              title='Jury'
              textColor='white'
              titleColor='yellow'
            />
          </div>
          <div className="col-lg-6">
            <img src={pic} className='obfc' alt="More than a platform it's a creative partner" width={'100%'} height={480} />
          </div>
        </div>
        <div className="s-120"></div>
      </Container>
    </section>
  )
}

export { Jury }
