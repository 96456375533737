import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Action } from '../elements/Action'

import { Slideshow } from '../elements/Slideshow'
import designers from '../data/designers'
import agencies from '../data/agencies'
import ai from '../data/ai'
import aii from '../data/aii'

const cai = false

const Join = () => {

  return (
    <Container className='tc'>
      <Title
        color='dark'
        sub='grey'
        title='Join to craftflow community'
        subtitle='Engage with our interactive challenges and quizzes designed for creative minds'
      />
      <div className='row'>
        <div className='col-lg-12'>
          <div className="s-40"></div>
          <div className="showcase flex jcsb aic">
            <h3 className="title-creator c-dark upp">Designers Showcase</h3>
            <a href='/' className="title-creator c-grey upp">show all</a>
          </div>
          <div className="s-32"></div>
          <Slideshow ai={cai}>
            {designers.map(e => (
              <a className='item' href={e.link} aria-label={e.title} target='blank' key={e.id}>
                <div className='item-content tc flex aic jcc'>
                  <div className="m-5">
                    <img src={e.avatar} alt={e.name} width={48} height={48} className='r-24 ma mb-3' />
                    <h4 className='title-name c-dark'>{e.name}</h4>
                    <div className="s-20"></div>
                    <h3 className='title-item c-dark'>{e.title}</h3>
                    <div className="s-20"></div>
                    <a href={e.link} className='button small bg-pink c-white'>View</a>
                  </div>
                </div>
                <img width={'100%'} height={400} src={e.pic} alt={e.title} className='work' />
              </a>
            ))}
          </Slideshow>
          <div className="s-64"></div>
          <div className="showcase flex jcsb aic">
            <h3 className="title-creator c-dark upp">Latest Agencies Creations</h3>
            <a href='/' className="title-creator c-grey upp">show all</a>
          </div>
          <div className="s-32"></div>
          <Slideshow ai={cai}>
            {agencies.map(e => (
              <a className='item' href={e.link} aria-label={e.title} target='blank' key={e.id}>
                <div className='item-content tc flex aic jcc'>
                  <div className="m-5">
                    <img src={e.avatar} alt={e.name} width={48} height={48} className='r-24 ma mb-3' />
                    <h4 className='title-name c-dark'>{e.name}</h4>
                    <div className="s-20"></div>
                    <h3 className='title-item c-dark'>{e.title}</h3>
                    <div className="s-20"></div>
                    <a href={e.link} className='button small bg-pink c-white'>View</a>
                  </div>
                </div>
                <img width={'100%'} height={400} src={e.pic} alt={e.title} className='work' />
              </a>
            ))}
          </Slideshow>
          <div className="s-64"></div>
          <div className="showcase flex jcsb aic">
            <h3 className="title-creator c-dark upp">AI Smartly Crafted</h3>
            <a href='/' className="title-creator c-grey upp">show all</a>
          </div>
          <div className="s-32"></div>
          <Slideshow ai={!cai}>
            {ai.map(e => (
              <a className='item ai' href={e.link} aria-label={e.title} target='blank' key={e.id}>
                <div className='item-content tc flex aic jcc'>
                  <div className="m-5">
                    <img src={e.avatar} alt={e.name} width={48} height={48} className='r-24 ma mb-3' />
                    <h4 className='title-name c-dark'>{e.name}</h4>
                    <div className="s-20"></div>
                    <h3 className='title-item c-dark'>{e.title}</h3>
                    <div className="s-20"></div>
                    <a href={e.link} className='button small bg-pink c-white'>View</a>
                  </div>
                </div>
                <img width={'100%'} height={400} src={e.pic} alt={e.title} className='work' />
              </a>
            ))}
          </Slideshow>
          <Slideshow ai={!cai}>
            {aii.map(e => (
              <a className='item ai' href={e.link} aria-label={e.title} target='blank' key={e.id}>
                <div className='item-content tc flex aic jcc'>
                  <div className="m-5">
                    <img src={e.avatar} alt={e.name} width={48} height={48} className='r-24 ma mb-3' />
                    <h4 className='title-name c-dark'>{e.name}</h4>
                    <div className="s-20"></div>
                    <h3 className='title-item c-dark'>{e.title}</h3>
                    <div className="s-20"></div>
                    <a href={e.link} className='button small bg-pink c-white'>View</a>
                  </div>
                </div>
                <img width={'100%'} height={400} src={e.pic} alt={e.title} className='work' />
              </a>
            ))}
          </Slideshow>
        </div>
      </div>
      <Action
        color='dark'
        back='pink'
        heading='Join the ranks of thousands of designers who are eagerly waiting for CraftFlow'
        text='Loin now to craftflow'
      />
      <div className='s-120'></div>
    </Container>
  )
}

export { Join }
