import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon from '../../assets/images/icons/offer.png'

const Offer = () => {
  return (
    <Container className='tc'>
      <Title
        color='dark'
        sub='grey'
        title='Special Offers &amp; Initiatives'
        subtitle='Be the first to explore new features and enjoy exclusive benefits'
      />
      <Card
        back='offer'
        sub='Unlock special rewards &amp; offers'
        icon={icon}
        width={64}
        height={64}
        title='CraftFlow'
        desc="Early Access"
        text='Learn More'
        textColor='white'
        titleColor='yellow'
        buttonColor='orange'
      />
      <div className="s-120"></div>
    </Container>
  )
}

export { Offer }
