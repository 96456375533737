import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon from '../../assets/images/icons/idea.png'
import pic from '../../assets/images/features.webp'

const Features = () => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title='Features &amp; Benefits'
        subtitle='Each feature of CraftFlow, from our intuitive interface to advanced analytics, is crafted to enhance your design experience'
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <Card
            back='features'
            sub='Collaboration &amp; Exposure in the Ever-Evolving Design World'
            icon={icon}
            width={60}
            height={85}
            title='CraftFlow'
            desc='All your needs for efficiency'
            textColor='white'
            titleColor='yellow'
          />
        </div>
        <div className="col-lg-6">
          <img src={pic} alt="More than a platform it's a creative partner" width={'100%'} height={480} />
        </div>
      </div>
      <div className="s-120"></div>
    </Container>
  )
}

export { Features }
