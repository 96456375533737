import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Slideshow = (props) => {

  const { children, ai } = props

  const Slides = () => {
    var settings = {
      autoplay: ai ? true : false,
      dots: false,
      arrows: ai ? false : true,
      infinite: true,
      pauseOnHover: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return (
      <section className={`slideshow mx-5 ${ai}`}>
        <Slider {...settings}>
          {children}
        </Slider>
      </section>
    );
  }

  return (
    <Slides />
  )
}

export { Slideshow }
