import React from 'react'
import { Container } from 'react-bootstrap'
import { Title } from '../elements/Title'
import { Card } from '../cards/Card'
import icon1 from '../../assets/images/icons/telegram.png'
import icon2 from '../../assets/images/icons/linkedin.png'
import pic1 from '../../assets/images/telegram-bg.jpg'
import pic2 from '../../assets/images/linkedin-bg.jpg'

const Social = () => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title='Join Now'
        subtitle="More than a platform it's a creative partner"
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <Card
            back='white'
            sub='Where we publish extra information not included in the newsletters.'
            icon={icon1}
            width={48}
            height={48}
            title='CraftFlow'
            desc="Join our Telegram"
            text='Telegram channel'
            textColor='dark'
            titleColor='pink'
            buttonColor='theme'
          />
        </div>
        <div className="col-lg-6">
          <img src={pic1} alt="More than a platform it's a creative partner" width={'100%'} height={480} className='obfc' />
        </div>
        <div className="col-lg-6">
          <img src={pic2} alt='The new home for your design aspirations' width={'100%'} height={480} className='obfc' />
        </div>
        <div className="col-lg-6">
          <Card
            back='white'
            sub='Make sure to follow our page on LinkedIn and give us a like! :)'
            icon={icon2}
            width={48}
            height={48}
            title='CraftFlow'
            desc='Follow as on Linkedin'
            text='CraftFlow on Linkedin'
            textColor='dark'
            titleColor='theme'
            buttonColor='blue'
          />
        </div>
      </div>
      <div className="s-120"></div>
    </Container>
  )
}

export { Social }
