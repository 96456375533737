import React from 'react';
import { Header } from '../components/partials/Header';
import { Intro } from '../components/partials/Intro';
import { About } from '../components/partials/About';
import { CtaOne } from '../components/partials/CtaOne';
import { Social } from '../components/partials/Social';
import { Pool } from '../components/partials/Pool';
import { Contest } from '../components/partials/Contest';
import { Jury } from '../components/partials/Jury';
import { Winner } from '../components/partials/Winner';

const ContestPage = () => {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <CtaOne />
      <Social />
      <Pool />
      <Contest />
      <Jury />
      <Winner />
    </>
  );
};

export { ContestPage };
